@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400);
body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    100deg,
    rgba(250, 214, 195, 0.8) 30%,
    #b0eae8 120%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.Dog img {
    width: 75%;
    -webkit-filter: grayscale();
            filter: grayscale();
    border-radius: 50%;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1);
    transition: 0.4s -webkit-filter ease;
    transition: 0.4s filter ease;
    transition: 0.4s filter ease, 0.4s -webkit-filter ease;
  }
  .Dog:hover img {
    -webkit-filter: none;
            filter: none;
  }
  
  .Dog .underline {
    text-decoration: none;
    border: 4px solid transparent;
    display: inline-block;
    vertical-align: top;
    line-height: 36px;
    text-transform: uppercase;
    color: black;
    letter-spacing: 0.2em;
    text-align: center;
    font-size: 1.4rem;
    margin: 10px;
    position: relative;
  }
  
  .underline::after {
    width: 0%;
    height: 4px;
    display: block;
    background-color: #fff;
    content: " ";
    position: absolute;
    top: 34px;
    left: 50%;
    transition: left 0.4s cubic-bezier(0.215, 0.61, 0.355, 1),
      width 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .Dog:hover .underline::after {
    width: 100%;
    top: 34px;
    left: 0;
  }
.DogDetails-card {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1);
  }
  
  .DogDetails-card img {
    -webkit-filter: grayscale();
            filter: grayscale();
    transition: 0.4s -webkit-filter ease;
    transition: 0.4s filter ease;
    transition: 0.4s filter ease, 0.4s -webkit-filter ease;
  }
  .DogDetails-card:hover img {
    -webkit-filter: none;
            filter: none;
  }
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

