.DogDetails-card {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1);
  }
  
  .DogDetails-card img {
    filter: grayscale();
    transition: 0.4s filter ease;
  }
  .DogDetails-card:hover img {
    filter: none;
  }